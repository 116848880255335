import { navigate } from 'gatsby'
import React, { useEffect } from 'react'
import Layout from '../components/Layout'

interface Props {
	path: string
	pageContext: {
		redirectPath: string
	}
}

const RootPage = ({ pageContext: { redirectPath } }: Props) => {
	useEffect(() => {
		let path = redirectPath
		if (!path.startsWith('/')) path = `/${path}`
		navigate(path, { replace: true })
	}, [])

	return <Layout>{null}</Layout>
}

export default RootPage
